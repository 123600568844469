import React from 'react';

import LogoImage from '../../assets/swiss_logo.png';
import './styles.scss';

const Logo: React.FC = () => {
  return (
    <a className="logo-wrapper" href="https://www.swiss.com/">
      <img className="logo" src={LogoImage} alt="Swiss logo" />
    </a>
  );
};

export default Logo;
